.logo {
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;

	a {
		display: block;
		background-image: url(/assets/img/logo-small.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		height: 39px;
		width: 38px;

		@include media-breakpoint-up(lg) {
			background-image: url(/assets/img/logo.svg);
			height: 48px;
			width: 136px;
		}
	}

	&.no-image {
		a {
			text-indent: 0;
			font-size: 30px;
		}
	}
}