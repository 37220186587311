.breadcrumb {
	background-color: $gray-100;
	display: inline-flex;
	flex-wrap: wrap;
	font-family: $font-basic-commercial;
	list-style: none;
	margin-bottom: $breadcrumb-margin-bottom;
	padding: $breadcrumb-padding-y $breadcrumb-padding-x;

	.back-btn + & {
		margin-left: $grid-gutter-width-half;
	}
}

.breadcrumb-item {
	& + .breadcrumb-item::before {
		background-image: url(/assets/img/icons/chevron-right-dark.svg);
		background-position: center;
		background-repeat: no-repeat;
		color: $breadcrumb-divider-color;
		content: '';
		display: inline-block;
		height: 12px;
		margin: 0 $breadcrumb-item-margin;
		width: 12px;
	}

	&.active {
		color: $breadcrumb-active-color;
		font-weight: bold;
	}
}
