// ---------------------------------------------
// Place overlay over element and use the width and height of the parent
// without having to know the dimensions of the parent;
// just make sure the parent has a position: relative;
// use the offset to create a smaller overlay
//
// Usage: @include fillout(4px); or @include fillout();
// ---------------------------------------------

@mixin fillout($offset: 0px) {
	bottom: $offset;
	display: block;
	left: $offset;
	position: absolute;
	right: $offset;
	top: $offset;
}
