.header {
	background-color: $white;
	bottom: calc(100% - 56px);
	box-shadow: 0 0px 24px -8px rgba($black, 0.16);
	border-bottom: 2px solid $gray-200;
	font-family: $font-basic-commercial;
	left: 0;
	overflow: visible;
	position: fixed;
	right: 0;
	transition: height 0.3s ease-in-out;
	width: 100%;
	z-index: index($elem-stack, header);

	@include media-breakpoint-up(lg) {
		bottom: auto;
		top: 0;
	}

	.site-search {
		display: none;
	}

	.top {
		display: none;
	}

	.mobile-nav-open & {
		@include media-breakpoint-up(lg) {
			overflow-y: auto;
			height: initial;
			overflow-y: visible;
		}
	}

	.row {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 9px 0;
		position: relative;

		@include media-breakpoint-up(lg) {
			padding: 12px 0;
		}
	}

	& + * {
		position: relative;
	}
}

.navigation {
	position: relative;
	z-index: index($elem-stack, navigation);
}


