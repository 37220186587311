.filter {
	.dropdown {
		display: block;

		@include media-breakpoint-up(sm) {
			display: none;
		}
	}

	.radiobuttonlist {
		display: none;

		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

// Project
.filter--projects {
	max-width: 570px;
	
	@include media-breakpoint-up(sm) {
		margin: 0 auto 50px auto;
		text-align: center;
	}

	.radiobuttonlist {
		@include media-breakpoint-up(sm) {
			justify-content: center;
		}
	}

	.label-radio--boxed {
		@include media-breakpoint-up(sm) {
			margin: 8px;
		}
	}
}

// Search
.filter--search {
	.label-radio--boxed {
		@include media-breakpoint-up(sm) {
			margin-right: 8px;
		}

		@include media-breakpoint-up(md) {
			margin-right: 16px;
		}
	}
}