// ---------------------------------------------
// @font-face
// var $dir-font is listed in _Config.scss
// Don't forget to add the proper mime-types
// for these font files in the web server config
//
// Usage, @root: @include font-face('Proxima Nova', 'proxima-nova-std-0384586', 400, 'normal');
// ---------------------------------------------

@use "sass:math";

@mixin font-face($font-name, $font-file, $font-weight: 400, $font-style: 'normal') {
	@font-face {
		font-family: '#{$font-name}';
		src:
			url('#{$dir-font}/#{$font-file}.woff2') format('woff2'),
			url('#{$dir-font}/#{$font-file}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-display: swap;
	}
}

// ---------------------------------------------
// Convert font-size from pixels to rem unit
// With pixel fallback for old web browsers
//
// Usage: @include font-size-rem(18);
// ---------------------------------------------

@mixin font-size-rem($size) {
	font-size: 0px + $size; // Fallback
	font-size: 0rem + math.div($size, $font-default-size); // Font-size you set on the HTML tag, see _config.scss
}