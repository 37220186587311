@include font-face("Roboto", "roboto/Roboto-Regular-webfont", 400);
@include font-face("Roboto", "roboto/Roboto-Medium-webfont", 500);
@include font-face("Roboto", "roboto/Roboto-Bold-webfont", 600);

@include font-face("Basic Commercial", "basic-commercial/BasicCommercial-LT-Bold", 700, normal);

@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-ExtraLight", 200, normal);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-Light", 300, normal);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-Regular", 400, normal);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-SemiBold", 600, normal);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-Bold", 700, normal);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-Black", 900, normal);

@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-ExtraLightItalic", 200, italic);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-LightItalic", 300, italic);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-Italic", 400, italic);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-SemiBoldItalic", 600, italic);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-BoldItalic", 700, italic);
@include font-face("Source Sans Pro", "source-sans-pro/SourceSansPro-BlackItalic", 900, italic);