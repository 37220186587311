//
// Textual form controls
//

.form-control {
	display: block;
	width: 100%;
	padding: $input-padding-y $input-padding-x;
	font-size: $font-size-base;
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;

	// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
	@if $enable-rounded {
		// Manually use the if/else instead of the mixin to account for iOS override
		border-radius: $input-border-radius;
	} @else {
		// Otherwise undo the iOS default
		border-radius: 0;
	}

	box-shadow: $input-box-shadow;
	transition: $input-transition;

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	// Customize the `:focus` state to imitate native WebKit styles.
	@include form-control-focus();

	// Placeholder
	&::placeholder {
		color: $input-placeholder-color;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}
}

select.form-control {
	&:not([size]):not([multiple]) {
		height: $input-height;
	}

	&:focus::-ms-value {
		// Suppress the nested default white text on blue background highlight given to
		// the selected option text when the (still closed) <select> receives focus
		// in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
		// match the appearance of the native widget.
		// See https://github.com/twbs/bootstrap/issues/19398.
		color: $input-color;
		background-color: $input-bg;
	}
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}

.form-control-checktoggle {
	position: relative;
	width: 40px;
	height: 24px;
	-webkit-appearance: none;
	background: $gray-400;
	outline: none;
	border-radius: 16px;
	transition: 0.7s;

	&:checked[type="checkbox"] {
		background-color: $color-10;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 18px;
		height: 18px;
		border-radius: 16px;
		background: $white;
		transform: scale(1.1);
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		transition: .5s;
	}
}

.form-control-checktoggle:checked[type="checkbox"]:before {
	left: 18px;
}

.umbraco-forms-field {
	margin-bottom: 20px;
}

.umbraco-forms-label {
	font-weight: bold;
}

.field-validation-error {
	color: $danger;
	display: block;
}

.umbraco-forms-tooltip.help-block {
	color: lighten($gray-900, 25%);
	display: block;
	font-size: 90%;
	margin-bottom: 10px;
	margin-top: -10px;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
	padding-top: calc(#{$input-padding-y} + #{$input-border-width});
	padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
	margin-bottom: 0; // Override the `<label>/<legend>` default
	font-size: inherit; // Override the `<legend>` default
	line-height: $input-line-height;
}

.col-form-label-lg {
	padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
	padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
	font-size: $font-size-lg;
	line-height: $input-line-height-lg;
}

.col-form-label-sm {
	padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
	padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
	font-size: $font-size-sm;
	line-height: $input-line-height-sm;
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: $input-padding-y;
	padding-bottom: $input-padding-y;
	margin-bottom: 0; // match inputs if this class comes on inputs with default margins
	line-height: $input-line-height;
	background-color: transparent;
	border: solid transparent;
	border-width: $input-border-width 0;

	&.form-control-sm,
	&.form-control-lg {
		padding-right: 0;
		padding-left: 0;
	}
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.form-control-sm {
	padding: $input-padding-y-sm $input-padding-x-sm;
	font-size: $font-size-sm;
	line-height: $input-line-height-sm;
	border-radius: $input-border-radius-sm;
}

select.form-control-sm {
	&:not([size]):not([multiple]) {
		height: $input-height-sm;
	}
}

.form-control-lg {
	padding: $input-padding-y-lg $input-padding-x-lg;
	font-size: $font-size-lg;
	line-height: $input-line-height-lg;
	border-radius: $input-border-radius-lg;
}

select.form-control-lg {
	&:not([size]):not([multiple]) {
		height: $input-height-lg;
	}
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
	margin-bottom: $form-group-margin-bottom;
}

.form-group-status {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 8px;
}

.form-group-status__item {
    background-color: $gray-200;
    border-radius: 100px;
    width: 100%;
    height: 8px;

    &.is-active {
        background-color: $green;
    }
}

.form-group-status__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2E1955;
}

.form-text {
	display: block;
	margin-top: $form-text-margin-top;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;

	> .col,
	> [class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	}

	.btn {
		margin-top: 15px;
	}
}


// Table search
.table-search {
	position: relative;
	margin: 15px 0;
	max-width: 688px;

	& + .dropdown--date-range {
		align-self: flex-start;
		margin-top: 15px;
		width: 100%;
	}

	.form-group {
		margin-bottom: 0px;
	}

	fieldset {
		margin-bottom: 0px;
		position: relative;
		pointer-events: all;
	}

	.form-control {
		border-radius: 8px;
		border: 2px solid $gray-400;
		padding: $grid-gutter-width-half 40px $grid-gutter-width-half $grid-gutter-width-half;
		width: 100%;
	}

	.btn[type="submit"] {
		border: 0 none;
		font-size: 25px;
		height: 60px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: 60px;

		&::before {
			background-image: url(/assets/img/icons/search-pink.svg);
			content: '';
			height: 30px;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
			position: absolute;
			width: 30px;
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: $grid-gutter-width-half -8px 0;
		padding: 0;

		li {
			background-color: $gray-100;
			border-radius: 30px;
			color: $text-grey;
			margin: 8px;
			padding: 3px 16px;

			&::after {
				background-image: url(/assets/img/icons/close-gray.svg);
				content: '';
				display: inline-block;
				height: 10px;
				margin-left: 10px;
				width: 10px;
			}

			&:last-child {
				background-color: transparent;
				padding-left: 0;
				padding-right: 0;

				&::after {
					display: none;
				}
			}
		}
	}
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
.radio input[type=radio], 
.checkbox input[type=checkbox], 
.radio-inline input[type=radio],
.checkbox-inline input[type=checkbox] {
	position: absolute;
}

.radio {
	.label-radio {
		display: inline-block;
		margin-bottom: 0;
		padding: 5px 10px 5px 30px;
		cursor: pointer;
		position: relative;

		&::before {
			background: $white;
			border-radius: 50%;
			border: 1px solid $gray-500;
			content: "";
			height: 18px;
			left: 0px;
			position: absolute;
			top: 7px;
			width: 18px;
		}

		&::after {
			background-color: $color-10;
			border-radius: 50%;
			content: '';
			height: 10px;
			left: 4px;
			position: absolute;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			top: 11px;
			width: 10px;
		}

		&.label-radio--boxed {
			border: 1px solid $gray-500;
			background-color: $white;
			margin-bottom: 8px;
			position: relative;
			
			&::before,
			&::after {
				margin-left: 8px;
			}

			&::before {
				top: calc(50% - 9px);
			}

			&::after {
				top: calc(50% - 5px);
			}
		}
	}

	input[type="radio"] {
		opacity: 0;

		&:checked ~ label {
			&::before {
				border-color: $color-10;
			}
			&::after {
				opacity: 1;
			}
		}

		&:disabled {
			& ~ label, & ~ label:after {
				opacity: .8;
				cursor: not-allowed;
			}
		}
	}
}

.checkbox {
	.label-checkbox {
		display: inline-block;
		margin-bottom: 0;
		padding: 5px 10px 5px 30px;
		cursor: pointer;
		position: relative;

		&::before {
			background: $white;
			border-radius: 5px;
			border: 1px solid $gray-500;
			content: "";
			height: 18px;
			left: 0px;
			position: absolute;
			top: 6px;
			width: 18px;
		}

		&::after {
			content: '';
			height: 8px;
			left: 3px;
			position: absolute;
			top: 9px;
			width: 12px;
			border-bottom: 3px solid $color-10;
			transform: rotate(-45deg);
			border-left: 3px solid $color-10;
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}
	}

	input[type="checkbox"] {
		opacity: 0;

		&:checked ~ label {
			&::after {
				opacity: 1;
			}
		}

		&:disabled {
			& ~ label, & ~ label:after {
				opacity: .8;
				cursor: not-allowed;
			}
		}
	}
}

.radio .label-radio input[type="radio"],
.checkbox .label-checkbox input[type="checkbox"] {
	top: 5px;
	left: 0;
	margin: 0;
}

// File upload
.file-upload {
	display: flex;
	align-items: center;

	.file-upload__label {
		flex: 0 1 calc(100% - 140px);
		border: 1px solid $input-border-color;
		border-radius: $input-border-radius;
		box-shadow: $input-box-shadow;
		line-height: 44px;
		height: 44px;
		padding-left: 10px;
		white-space: nowrap;
		width: calc(100% - 140px);
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			flex: 0 1 calc(100% - 168px);
			width: calc(100% - 168px);
		}
	}

	.file-upload__btn {
		flex: 0 0 140px;
		overflow: hidden;
		position: relative;

		@include media-breakpoint-up(lg) {
			flex: 0 0 168px;
		}

		input[type='file'] {
			background: white;
			cursor: pointer;
			display: block;
			font-size: 100px;
			min-height: 100%;
			min-width: 100%;
			opacity: 0;
			outline: none;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
		}

		& + .filelabel {
			display: inline-block;
			font-style: italic;
			margin-top: 6px;
		}
	}
}


// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

	// Because we use flex, the initial sizing of checkboxes is collapsed and
	// doesn't occupy the full-width (which is what we want for xs grid tier),
	// so we force that here.
	.form-check {
		width: 100%;
	}

	// Kick in the inline
	@include media-breakpoint-up(sm) {
		label {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
		}

		// Inline-block all the things for "inline"
		.form-group {
			display: flex;
			flex: 0 0 auto;
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: 0;
		}

		// Allow folks to *not* use `.form-group`
		.form-control {
			display: inline-block;
			width: auto; // Prevent labels from stacking above inputs in `.form-group`
			vertical-align: middle;
		}

		// Make static controls behave like regular ones
		.form-control-plaintext {
			display: inline-block;
		}

		.input-group {
			width: auto;
		}

		// Remove default margin on radios/checkboxes that were used for stacking, and
		// then undo the floating of radios and checkboxes to match.
		.form-check {
			display: flex;
			align-items: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}
		.form-check-input {
			position: relative;
			margin-top: 0;
			margin-right: $form-check-input-margin-x;
			margin-left: 0;
		}

		.custom-control {
			align-items: center;
			justify-content: center;
		}
		.custom-control-label {
			margin-bottom: 0;
		}
	}
}


.form-group-floating {
	input {
		padding: 1.1rem 0.9375rem 0.3rem;
	}

	label {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		padding: 7px 1.4rem 0;

		&.field-validation-error {
			left: auto;
			right: 0;
		}
	}
}

.form__divider {
	display: block;
	width: 100%;
	height: 2px;
	background-color: #EAE5F2;
	margin: 24px 0px;
}