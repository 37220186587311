// BASE STYLES
.btn {
	cursor: pointer;
	display: inline-block;
	font-weight: $btn-font-weight;
	text-decoration: none;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	position: relative;
	border: $btn-border-width solid transparent;
	padding: $btn-padding-y-sm $btn-padding-x-sm;
	font-size: $font-size-base;
	line-height: $btn-line-height;
	border-radius: 42px;
	transition: $btn-transition;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	font-family: $font-basic-commercial;
	z-index: index($elem-stack, button);

	@include media-breakpoint-up(xl) {
		font-size: 18px;
		line-height: 18px;
		padding: $btn-padding-y-lg $btn-padding-x-lg;
		white-space: normal;
	}

	// Share hover and focus styles
	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:focus,
	&.focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}

	// Opinionated: add "hand" cursor to non-disabled .btn elements
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-image: none;
		box-shadow: $btn-active-box-shadow;

		&:focus {
			box-shadow: $btn-focus-box-shadow, $btn-active-box-shadow;
		}
	}
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}




// ALTERNATE BUTTONS
@each $color, $value in $button-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
		box-shadow: 0px 17px 13px -8px rgba($value, 0.25);
	}
}

@each $color, $value in $button-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
		box-shadow: 0px 17px 13px -8px rgba($value, 0.25);
	}
}

.btn-outline-secondary {
	border-color: $color-12;
}


// LINK BUTTONS
// Make a button look and behave like a link
.btn-link {
	font-weight: $font-weight-normal;
	color: $link-color;
	background-color: transparent;

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		background-color: transparent;
		border-color: transparent;
	}

	&:focus,
	&.focus {
		text-decoration: $link-hover-decoration;
		border-color: transparent;
		box-shadow: none;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
	}

	// No need for an active state here
}




// BLOCK BUTTON
.btn-block {
	display: block;
	width: 100%;

	// Vertically space out multiple block buttons
	& + .btn-block {
		margin-top: $btn-block-spacing-y;
	}
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	// Specificity overrides
	&.btn-block {
		width: 100%;
	}
}




// Button slider
.btn--slider-prev, .btn--slider-next,
%btn--slider-prev, %btn--slider-next {
	background-color: $gray-500;
	border-radius: 50%;
	border: 0 none;
	cursor: pointer;
	height: 30px;
	outline: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	z-index: index($elem-stack, button);
	transition: $transition-base;

	&:hover {
		background-color: $gray-500;
		box-shadow: 0 0 5px 0px rgba($black, 0.25);
	}

	&::before {
		content: '';
		display: inline-block;
		height: 12px;
		left: calc(50% - 6px);
		position: absolute;
		top: calc(50% - 6px);
		width: 12px;
	}

	@include media-breakpoint-up(lg) {
		width: 40px;
		height: 40px;
	}
}

.btn--slider-prev,
%btn--slider-prev {
	left: 15px;

	@include media-breakpoint-up(md) {
		left: 0;
	}

	&::before {
		background-image: url(/assets/img/icons/chevron-left-dark.svg);
	}
}

.btn--slider-next,
%btn--slider-next {
	right: 15px;

	@include media-breakpoint-up(md) {
		right: 0;
	}

	&::before {
		background-image: url(/assets/img/icons/chevron-right-dark.svg);
	}
}

.swiper-button-disabled {
	display: none;
}


// BACK BUTTON
.back-btn {
	display: inline-block;
	margin-bottom: 8px;
	position: relative;
	text-decoration: none;

	&::before {
		background-color: white;
		background-image: url(/assets/img/icons/chevron-left-dark.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 6px;
		border-radius: 50%;
		border: 2px solid $color-14;
		box-shadow: 0px 4px 8px -3px rgba($color-11, 0.25);
		content: '';
		display: inline-block;
		height: 24px;
		margin-right: 8px;
		vertical-align: middle;
		width: 24px;
	}
}

// READ MORE
.read-more {
	font-weight: 700;
	text-decoration: none;
	position: relative;
	z-index: 1;

	&::after {
		background-image: url(/assets/img/icons/chevron-right-dark.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		content: '';
		display: inline-block;
		height: 12px;
		line-height: 1;
		margin-left: 5px;
		text-align: center;
		vertical-align: baseline;
		width: 12px;

		@include media-breakpoint-up(lg) {
			margin-left: 10px;
		}
	}

	&:not(.btn) {
		&:hover {
			color: $color-20;

			&::before {
				background-color: $gray-100;
				border-radius: 10px;
				bottom: -2px;
				content: '';
				margin-left: -6px;
				position: absolute;
				top: -2px;
				width: calc(100% + 12px);
				z-index: -1;
			}

			&::after {
				background-image: url(/assets/img/icons/chevron-right-pink.svg);
			}
		}
	}

	&.btn-primary,
	&.btn-secondary {
		&::after {
			background-image: url(/assets/img/icons/chevron-right-light.svg);
		}
	}
}

// SCROLL DOWN
.scroll-down {
	font-weight: 700;
	text-decoration: none;
	position: relative;
	z-index: 1;

	&::after {
		background-image: url(/assets/img/icons/arrow-down-dark.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		content: '';
		display: inline-block;
		height: 12px;
		line-height: 1;
		margin-left: 5px;
		text-align: center;
		vertical-align: middle;
		width: 12px;
	}

	&:not(.btn) {
		&:hover {
			color: $color-20;

			&::before {
				background-color: $gray-100;
				border-radius: 10px;
				bottom: -2px;
				content: '';
				margin-left: -6px;
				position: absolute;
				top: -2px;
				width: calc(100% + 12px);
				z-index: -1;
			}

			&::after {
				background-image: url(/assets/img/icons/arrow-down-pink.svg);
			}
		}
	}

	&.btn-primary,
	&.btn-secondary {
		&::after {
			background-image: url(/assets/img/icons/chevron-right-light.svg);
		}
	}
}

// SHOW MORE / SHOW LESS
.show-more,
.show-less {
	display: block;
	text-align: center;
	text-decoration: none;
	padding: $grid-gutter-width 0 0;

	&::after {
		background-color: white;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 10px;
		border-radius: 50%;
		border: 2px solid $color-14;
		box-shadow: 0px 4px 13px 0px rgba(130, 109, 174, 0.25);
		content: '';
		display: inline-block;
		height: 24px;
		margin-left: 5px;
		vertical-align: bottom;
		width: 24px;

		@include media-breakpoint-up(lg) {
			margin-left: 10px;
		}
	}
}

.show-more {
	&::after {
		background-image: url(/assets/img/icons/chevron-down-dark.svg);
	}
}

.show-less {
	&::after {
		background-image: url(/assets/img/icons/chevron-up-dark.svg);
	}
}

// SCROLL TO TOP
.btn--scroll-to-top {
	display: none;
	bottom: -50px; 
	opacity: 0;
	position: fixed; 
	right: 10px; 
	transition: all 0.3s ease-in-out;
	z-index: 100;
	z-index: index($elem-stack, scroll-to-top);

	&.is-visible {
		bottom: 10px; 
		opacity: 1;
	}
}
