.menu-trigger {
	// all: initial;
	// position: relative;
	// width: 40px;
	// height: 40px;
	// margin-top: auto;
	// margin-bottom: auto;
	// margin-right: 0;
	// margin-left: auto;
	// display: block;
	// text-decoration: none;
	// cursor: pointer;
	padding: 10px 20px 6px;
	border-radius: 5px;

	&:active,
	&:focus {
		outline: none;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}

	span::before,
	span,
	span::after {
		background-color: $color-10;
		content: "";
		display: block;
		width: 26px;
		height: 1px;
		position: relative;
		left: 50%;
		opacity: 1;
		transform: translateX(-50%);
		transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
	}

	span {
		left: 50%;
		margin-bottom: 8px;
		margin-top: 8px;
		top: 50%;
		transform: translate(-50%, -50%);

		&::before {
			top: -8px;
		}

		&::after {
			top: 8px;
		}
	}

	.menu-trigger__text {
		color: $color-10;
		font-size: 10px;
		font-weight: 700;
		line-height: 12px;
		margin-top: 18px;
		text-align: center;
		white-space: nowrap;
	}

	&.active {
		background-color: $gray-100;

		span {
			width: 0;

			&::after,
			&::before {
				top: 0;
			}

			&::before {
				transform: translateX(-50%) rotate(-45deg);
			}

			&::after {
				transform: translateX(-50%) rotate(45deg);
			}
		}

		.menu-trigger__text {
			color: $color-20;
		}
	}
}
