.header {
	height: 56px;

	@include media-breakpoint-up(lg) {
		height: 132px;
	}

	&::after {
		@include media-breakpoint-up(lg) {
			content: "";
			display: block;
			width: 100%;
			height: 60px;
			position: absolute;
			top: 72px;
			right: 0;
			left: 0;
			background-color: $gray-100;
			z-index: -1;
		}
	}

	.menu {
		@include media-breakpoint-up(lg) {
			left: 0;
			margin: 0;
			position: absolute;
			top: 77px;
			width: 100%;

			.menu__list {
				padding: 0;
				width: 100%;
			}
		}

		.menu__item {
			position: static;

			&.has-child {
				.menu__subwrap {
					width: 100%;
					box-shadow: none;

					&::before {
						background-color: $color-10;
						box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
						content: '';
						height: calc(100% + 1px);
						left: 50%;
						position: absolute;
						top: 0;
						transform: translateX(-50%);
						width: 100vw;
					}
				}
			}
		}
	}

	.menu-trigger {
		bottom: 4px;
		position: fixed;
		right: calc(10% - 31px);
		z-index: index($elem-stack, menu-mobile);
	}

	& + * {
		margin-top: 56px;

		@include media-breakpoint-up(lg) {
			margin-top: 132px;
		}
	}

	.cta-link {
		right: 0;
		position: absolute;
		top: 8px;

		@include media-breakpoint-up(lg) {
			top: 15px;
		}
	}

	.site-search {
		@include media-breakpoint-up(lg) {
			margin-top: 30px;
			margin-bottom: 0px;
		}
	}
}

.header-04 {
	@media (min-width: 992px) {
		height: 72px;
	}

	& + * {
		@include media-breakpoint-up(lg) {
			margin-top: 72px;
		}
	}

	&::after {
		content: none;
	}

	.mobile-nav {
		width: auto;
		right: 0;
		top: 15px;
	}
}

.logo__subline {
	display: none;

	@include media-breakpoint-up(md) {
		color: $color-10;
		display: inline-block;
		font-weight: 700;
		line-height: 1;
		max-width: 170px;
		text-align: center;
	}

	@include media-breakpoint-up(xl) {
		max-width: 195px;
	}
}