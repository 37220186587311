//baseline-functions

// Base size and ratio
$base: 1; //= 16px
$ratio: 1.2; // Perfect Fifth

@mixin ms($value, $ms-ratio: $ratio, $ms-base: $base){
	$size: pow($ms-ratio, $value)*$ms-base;
	$sizepx: $size*16;
	font-size: $sizepx +px;
	font-size: $size +rem;
}
