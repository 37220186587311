.account__menu {
    margin-bottom: 0;

    .list__item {
        border-top: 2px solid $color-14;
        margin: 0px;
        padding-top: 20px;
        position: relative;

        & + .list__item {
            margin-top: 20px;
        }

        a {
            text-decoration: none;
        }

        &::after {
            right: 0;
            top: 27px;

            @include media-breakpoint-up(md) {
               top: 7px;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
            padding: 0px;
            border-top: initial;
        }
    }

    + .btn {
        width: initial;
        display: none;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin-top: $grid-gutter-width;
        }
    }
}

.account__user {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        display: none;
    }

    p {
        padding-left: 42px;
        margin-right: 20px;
        font-weight: 700;
        position: relative;

        &::before {
            background-image: url(/assets/img/icons/user.svg);
            content: '';
            height: 32px;
            left: 0;
            position: absolute;
            top: 3px;
            width: 32px;
        }

        span {
            display: block;
            font-weight: 400;
        }
    }

    .btn {
        height: 44px;
    }
}

.account__content {
    margin: 12px -16px 0;
    padding: 24px;
    box-shadow: 0px 8px 32px -8px rgba(112, 108, 107, 0.22);
    border-radius: 8px;

    @include media-breakpoint-up(md) {
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
    }
}

.account__content-title {
    position: relative;
    padding-left: 40px;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-decoration: none;

    @include media-breakpoint-up(md) {
        cursor: default;
        font-size: 40px;
        line-height: 54px;
        pointer-events: none;

        &:hover {
            color: $color-10;
        }
    }

    &::before {
        content: "";
        display: inline-block;
        background-image: url(/assets/img/icons/chevron-left-dark.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 6px auto;
        position: absolute;
        left: 0px;
        top: 3px;
        height: 24px;
        width: 24px;
        border-radius: 45px;
        border: 2px solid $color-14;
        box-shadow: 0px 17px 13px -8px rgb(65 35 120 / 25%);
    }

    @include media-breakpoint-up(md) {
        padding: 0px;

        &::before {
            content: none;
        }
    }
}