// ---------------------------------------------
// Block
// ---------------------------------------------

.message-box {
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	margin-top: 20px;
}

// ---------------------------------------------
// Element
// ---------------------------------------------

.message-box__content {
	position: relative;
	background-color: $gray-100;
	border: 1px solid $color-20;
	padding: 20px;

	form {
		.label-radio {
			width: 100%;
		}
	}

	.form__subscribe-button {
		min-width: 150px;
		margin: 0;
	}
}

.message-box__close {
	background-image: url(/assets/img/icons/close-dark.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	height: 10px;
	width: 10px;
	position: absolute;
	right: 15px;
	top: 15px;
}

// ---------------------------------------------
// Modifier
// ---------------------------------------------
.message-box--image {
	.message-box__content {
		display: flex;
		max-height: 200px;
	}

	.message-box__image {
		width: 100px;

		@include media-breakpoint-up(lg) {
			width: 140px;
			max-height: 160px;
		}	
	}

	.message-box__text {
		margin-left: 20px;
	}
}

.message-box--sidetab {
	bottom: 0px;
	max-width: 460px;
	position: fixed;
	right: -436px;
	transition: right 0.3s ease-in-out;
	width: 100%;
	z-index: index($elem-stack, message-box--sidetab);

	@include media-breakpoint-up(md) {
		bottom: 50px;
	}

	@include media-breakpoint-up(xl) {
		bottom: 100px;
	}

	.message-box__content {
		box-shadow: none;
		border: none;
		background-color: $gray-100;
		padding: 20px 20px 20px 44px;
	}

	.message-box__close {
		background-image: none;
		background-color: $color-10;
		height: 100%;
		left: 0px;
		top: 0;
		width: 24px;

		&::after {
			background-image: url(/assets/img/icons/chevron-left-light.svg);
			content: '';
			height: 16px;
			top: calc(50% - 8px);
			left: calc(50% - 8px);
			position: absolute;
			width: 16px;
		}
	}

	&.is-open {
		right: 0;

		.message-box__close::after {
			transform: rotate(180deg);
		}
	}
}
