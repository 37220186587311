// ---------------------------------------------
// Directories
// ---------------------------------------------
$dir-font: '/assets/font';
$dir-img: '/assets/img';
$dir-icon: '~@/assets/icons';
$dir-photo: '/Media';

// ---------------------------------------------
// Breakpoints
// ---------------------------------------------
	// BOOTSTRAP BREAKPOINTS
	// 	.col-xs-	< 576px		(container width 100%)
	// 	.col-sm-	> 576px		(container width 540px)
	// 	.col-md-	> 768px		(container width 750px)
	// 	.col-lg-	> 992px		(container width 980px)
	// 	.col-xl-	> 1200px	(container width 1170px)

	// 	@include media-breakpoint-up(sm) { ... } 				// Small devices (landscape phones, 576px and up)
	// 	@include media-breakpoint-up(md) { ... } 				// Medium devices (tablets, 768px and up)
	// 	@include media-breakpoint-up(lg) { ... } 				// Large devices (desktops, 992px and up)
	// 	@include media-breakpoint-up(xl) { ... } 				// Extra large devices (large desktops, 1200px and up)

	// 	@include media-breakpoint-down(sm) { ... } 				// Extra small devices (portrait phones, less than 576px)
	// 	@include media-breakpoint-down(md) { ... } 				// Small devices (landscape phones, less than 768px)
	// 	@include media-breakpoint-down(lg) { ... } 				// Medium devices (tablets, less than 992px)
	// 	@include media-breakpoint-down(xl) { ... } 				// Large devices (desktops, less than 1200px)

	// 	@include media-breakpoint-only(xs) { ... } 				// Extra small devices (portrait phones, less than 576px)
	// 	@include media-breakpoint-only(sm) { ... } 				// Small devices (landscape phones, between 576px and 767.98px)
	// 	@include media-breakpoint-only(md) { ... } 				// Medium devices (tablets, between 768px and 991.98px)
	// 	@include media-breakpoint-only(lg) { ... } 				// Large devices (desktops, between992px and 1199.98px)
	// 	@include media-breakpoint-only(xl) { ... } 				// Extra large devices (large desktops, 1200px and up)

	// 	@include media-breakpoint-between(md, xl) { ... } 		// Apply styles starting from medium devices and up to extra large devices

$mq-breakpoints: (
	'sm': 576px,
	'md': 768px,
	'lg': 992px,
	'xl': 1200px,
	'hg': 1500px,
	'wd': 1920px
);

// ---------------------------------------------
// Fonts
// ---------------------------------------------

$font-basic-commercial: "Basic Commercial", 'Helvetica', sans-serif;
$font-source-sans: 'Source Sans Pro', 'Helvetica', sans-serif;


// ---------------------------------------------
// Colors
// ---------------------------------------------

$color-10: #412378;
$color-11: #6F569E;
$color-12: #A392C1;
$color-13: #C8BEDC;
$color-14: #EAE5F2;

$color-20: #E6006E;
$color-21: #EA5188;
$color-22: #F086A7;
$color-23: #F8CAD9;
$color-24: #F9EAEF;

$color-30: #009CC4;
$color-31: #12B9E3;
$color-32: #69D3EE;
$color-33: #B6ECF8;
$color-34: #E6F7FC;

$color-40: #EBA835;
$color-41: #F0B959;
$color-42: #F4CB84;
$color-43: #F8DCAE;
$color-44: #FBEDD7;

$color-50: #55B26A;
$color-51: #70C885;
$color-52: #97DBA7;
$color-53: #C2E9CB;
$color-54: #E0F3E5;

$color-60: #DA3B3F;
$color-61: #EF6B6E;
$color-62: #F68084;
$color-63: #FAB1B5;
$color-64: #FFDCDE;

$text-purple: #2E1955;
$text-grey: #776A63;


// --------------------------------------------------
// 2.0 Z-INDEX
// --------------------------------------------------
	// z-index: index($elem-stack, [name from the list below]);
	$elem-stack: (
	    banner-item
	    button
	    // price-table-sticky-column
	    // steps-item
	    // location-tool
	    message-box--sidetab
	    // windows-info
	    off-canvas
	    navigation
	    menu-mobile
	    // newsletter-form
	    // overlay-icon
	    // menu-item
	    // slick-slider-arrow
	    // windows-link
	    // expand
	    header
	    // menu
	    // logo
	    // brand
	    scroll-to-top
	    // cta-link
	    // menu-list
	    cookiewall-overlay
	    cookie-notice
	);


// --------------------------------------------------
// 3.0 ITEMS IN A ROW
// --------------------------------------------------
	$items-1:100%;
	$items-2:50%;
	$items-3:33.3333333%;
	$items-4:25%;
	$items-5:20%;

// --------------------------------------------------
// 4.0 SIZES
// --------------------------------------------------
	// BASE
	$base-header-height:90px;
	$base-header-height_sm:160px;
	$base-nav-main-height_sm:60px;
	$base-nav-main-dropdown-width_sm:240px;
	$base-nav-main-dropdown-width-wider_sm:480px;
	$base-border-width:1px;

	// BANNERS

	$takeover-banner:calc(100vh - 90px);
	$takeover-banner_sm: calc(100vh - 160px);
