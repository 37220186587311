// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: $nav-link-padding-y $nav-link-padding-x;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	// Disabled state lightens text
	&.disabled {
		color: $nav-link-disabled-color;
	}
}

//
// Tabs
//
.nav-tabs {
	.nav-item {
		margin-bottom: -$nav-tabs-border-width;
	}

	.nav-link {
		background-color: $color-14;
		border-radius: 6px 6px 0px 0px;
		color: $color-11;
		text-decoration: none;
		font-family: $font-basic-commercial;
		font-weight: 700;
		padding: 6px 12px 12px;

		@include media-breakpoint-up(md) {
			padding: 8px 16px 16px;
		}

		@include media-breakpoint-up(lg) {
			padding: 10px 20px 20px;
		}

		@include media-breakpoint-up(hg) {
			padding: 12px 24px 24px;
		}

		&:hover,
		&:focus {
			background-color: $color-13;
		}

		&.disabled {
			color: $nav-link-disabled-color;
			background-color: transparent;
			border-color: transparent;
		}
	}

	.nav-link.active,
	.nav-item.show .nav-link {
		background-color: $white;
		color: $color-10;
	}

	.dropdown-menu {
		// Make dropdown border overlap tab border
		margin-top: -$nav-tabs-border-width;
		// Remove the top rounded corners here since there is a hard edge above the menu
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}


//
// Pills
//

.nav-pills {
	.nav-link {
		border-radius: $nav-pills-border-radius;
	}

	.nav-link.active,
	.show > .nav-link {
		color: $nav-pills-link-active-color;
		background-color: $nav-pills-link-active-bg;
	}
}


//
// Justified variants
//

.nav-fill {
	.nav-item {
		flex: 1 1 auto;
		text-align: center;
	}
}

.nav-justified {
	.nav-item {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
	> .tab-pane {
		display: none;
	}
	> .active {
		display: block;
	}
}
