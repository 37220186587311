.footer {
	background-color: $light;
	margin-bottom: 64px;

	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
}

// Footer TOP
// .footer__top {
// 	padding: 15px 0 0;

// 	@include media-breakpoint-up(md) {
// 		display: flex;
// 		flex-wrap: wrap;
// 		margin: 0 #{-$grid-gutter-width-half};
// 		padding: 30px 0 0;
// 	}

// 	@include media-breakpoint-up(lg) {
// 		flex-wrap: nowrap;
// 		padding: 45px 0;
// 	}
// }

// .footer__logo,
// .footer__links,
// .footer__company-details {
// 	padding: 0 0 $grid-gutter-width;

// 	h4 {
// 		white-space: nowrap;
// 	}

// 	@include media-breakpoint-up(lg) {
// 		padding: 0 $grid-gutter-width-half;
// 	}
// }

// .footer__logo {
// 	@include media-breakpoint-up(md) {
// 		display: flex;
// 		flex-direction: column;
// 		flex: 0 1 50%;
// 		height: 100%;
// 	}

// 	@include media-breakpoint-up(lg) {
// 		flex: 0 1 25%;
// 	}

// 	.logo {
// 		height: 45px;
// 		margin-bottom: 20px;
// 		width: 100%;

// 		a {
// 			background-position: left center;
// 			height: 45px;
// 			width: 100%;
// 		}
// 	}
// }

// .footer__links {
// 	ul {
// 		list-style: none;
// 		margin: 0;
// 		padding: 0;

// 		li {
// 			margin-bottom: 10px;

// 			a {
// 				display: block;
// 				position: relative;
// 			}
// 		}
// 	}

// 	@include media-breakpoint-up(sm) {
// 		display: flex;
// 		flex: 0 0 100%;
// 		order: -1;
// 	}

// 	@include media-breakpoint-up(lg) {
// 		flex: 0 1 50%;
// 		order: 0;
// 	}
// }

// .footer__links-list {
// 	& + & {
// 		@include media-breakpoint-up(sm) {
// 			margin-left: $grid-gutter-width-half;
// 		}

// 		@include media-breakpoint-up(lg) {
// 			margin-left: $grid-gutter-width;
// 		}
// 	}

// 	@include media-breakpoint-up(sm) {
// 		flex: 1 1 auto;
// 	}
// }

// .footer__company-details {
// 	@include media-breakpoint-up(md) {
// 		display: flex;
// 		flex-direction: column;
// 		flex: 0 1 50%;
// 	}

// 	@include media-breakpoint-up(lg) {
// 		flex: 0 1 25%;
// 	}
// }

// Footer BOTTOM
.footer__bottom {
	&::before {
		background-color: $white;
		border-top: 1px solid $gray-200;
		content: '';
		height: 100%;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		width: 100vw;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0;
		padding: 0;
		position: relative;
		list-style: none;

		@include media-breakpoint-up(lg) {
			padding: 20px 0 25px;
		}

		li {
			margin: 0 12px;
			padding: 10px 0;

			a,
			div {
				padding: 0;
			}

			@include media-breakpoint-up(lg) {
				display: inline-block;
				margin: 0 0 0 40px;
				padding: 0;
			}

			&:first-child {
				@include media-breakpoint-up(lg) {
					margin-left: 0;
				}
			}
		}
	}

	.copyright {
		display: none;
		flex: 0 0 100%;
		
		@include media-breakpoint-up(lg) {
			flex: 0 0 auto;
		}
	}
}