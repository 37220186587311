.icon {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: 36px;
	margin: 0 auto 0px auto;
	vertical-align: middle;
	width: 36px;

	&.icon--small {
		height: 24px;
		width: 24px;
	}
}

.icon--overview {
	background-image: url(/assets/img/icons/overview.svg);
}

.icon--loans {
	background-image: url(/assets/img/icons/loans.svg);
}

.icon--grants {
	background-image: url(/assets/img/icons/grants.svg);
}

.icon--running-account {
	background-image: url(/assets/img/icons/running-account.svg);
}

.icon--requests {
	background-image: url(/assets/img/icons/requests.svg);
}

.icon--documents {
	background-image: url(/assets/img/icons/documents.svg);
}

.icon--pdf {
	background-image: url(/assets/img/icons/pdf.svg);
}

.icon--xml {
	background-image: url(/assets/img/icons/xml.svg);
}

.icon--csv {
	background-image: url(/assets/img/icons/csv.svg);
}

.icon--download {
	background-image: url(/assets/img/icons/download.svg);
}

.icon--calendar {
	background-image: url(/assets/img/icons/calendar.svg);
}

.icon--envelope-02 {
	background-image: url(/assets/img/icons/envelope-02.svg);
}

.icon--phone-01 {
	background-image: url(/assets/img/icons/phone-01.svg);
}

.icon--phone-02 {
	background-image: url(/assets/img/icons/phone-02.svg);
}

.icon--chevron-down {
	background-image: url(/assets/img/icons/chevron-down-dark.svg);
}