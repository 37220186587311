// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
	position: relative;
}

.dropdown-toggle {
	border-radius: 8px;
	font-family: $font-source-sans;
	font-weight: 400;
	padding: 20px calc(#{$grid-gutter-width-half} * 3) 20px $grid-gutter-width-half;
	text-align: left;
	width: 100%;

	&::after {
		background-image: url(/assets/img/icons/chevron-down-dark.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: "";
		height: 22px;
		position: absolute;
		right: $grid-gutter-width-half;
		top: calc(50% - 11px);
		width: 22px;
		z-index: 1
	}

	span:first-child {
		color: $text-grey;
		transition: all 0.2s ease-in-out;
		white-space: nowrap;
	}

	.show > &.btn-outline-gray { // Dropdown toggle is clicked
		border-color: $color-10;
	}

	&.show { // Dropdown list is visible
		border-bottom: none;
		border-bottom-left-radius: 0;
	}

	&.has-content {
		& > span:first-child {
			font-size: 14px;
			left: $grid-gutter-width-half;
			position: absolute;
			top: 4px;
		}

		& > span:last-child {
			bottom: -8px;
			color: $color-10;
			font-size: 16px;
			position: relative;
		}
	}

	.dropdown--export & {
		border-radius: 50px;
		font-family: $font-basic-commercial;
		font-weight: 700;
		padding: $btn-padding-y-sm $btn-padding-x-sm;

		&::after {
			display: none;
		}
	}

	.dropdown--export.show & {
		border-color: $color-20;
		color: $color-20;
	}
}

// The dropdown menu
.dropdown-menu {
	background-clip: padding-box;
	background-color: $dropdown-bg;
	border-radius: 8px;
	box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.16);
	color: $body-color;
	display: none; // none by default, but block on "open" of the menu
	float: left;
	font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
	left: 0;
	list-style: none;
	margin: $dropdown-spacer 0 0; // override default ul
	min-width: $dropdown-min-width;
	outline: none;
	padding: $dropdown-padding-y 0;
	position: absolute;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	top: 100%;
	z-index: 2;

	.dropdown--export & {
		padding: $grid-gutter-width $grid-gutter-width-half;
		width: 274px;

		li + li {
			margin-top: $grid-gutter-width-half;
		}
	}
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
	.dropdown-menu {
		margin-top: 0;
		margin-bottom: $dropdown-spacer;
	}

	.dropdown-toggle {
		@include caret(up);
	}
}

.dropright {
	.dropdown-menu {
		margin-top: 0;
		margin-left: $dropdown-spacer;
	}

	.dropdown-toggle {
		@include caret(right);
		&::after {
			vertical-align: 0;
		}
	}
}

.dropleft {
	.dropdown-menu {
		margin-top: 0;
		margin-right: $dropdown-spacer;
	}

	.dropdown-toggle {
		@include caret(left);
		&::before {
			vertical-align: 0;
		}
	}
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
	height: 0;
	margin: ($spacer / 2) 0;
	overflow: hidden;
	border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
	display: block;
	width: 100%; // For `<button>`s
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	clear: both;
	font-weight: 600;
	text-align: inherit; // For `<button>`s
	white-space: nowrap; // prevent links from randomly breaking onto new lines
	background-color: transparent; // For `<button>`s
	border: 0; // For `<button>`s
	text-decoration: none;

	.dropdown--export & {
		align-items: center;
		line-height: 20px;
		display: flex;
		padding: 0;
		position: relative;
		white-space: normal;

		&::before {
			background-image: url(/assets/img/icons/csv.svg);
			background-repeat: no-repeat;
			content: '';
			flex: 0 0 32px;
			height: 32px;
			left: 0;
			margin-right: 8px;
			top: calc(50% - 16px);
			width: 32px;
		}
	}

	&:hover,
	&:focus {
		color: $dropdown-link-hover-color;
		text-decoration: none;
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
		text-decoration: none;
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		background-color: transparent;
		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}
}

.dropdown-menu.show {
	display: block;
}

// Dropdown section headers
.dropdown-header {
	display: block;
	padding: $dropdown-padding-y $dropdown-item-padding-x;
	margin-bottom: 0; // for use with heading elements
	font-size: $font-size-sm;
	color: $dropdown-header-color;
	white-space: nowrap; // as with > li > a
}

// Dropdown Date range picker
.dropdown--date-range {
	height: 62px;
	max-width: 344px;
	width: 100%;
	z-index: 3;

	.dropdown-toggle {
		padding-left: 58px;

		&::before {
			background-image: url(/assets/img/icons/calendar.svg);
			content: '';
			height: 32px;
			left: 14px;
			position: absolute;
			top: calc(50% - 16px);
			width: 32px;
		}

		&.has-content {
			& > span:first-child {
				left: initial;
			}

			& > span:last-child {
				bottom: -8px;
				color: $color-10;
				font-size: 16px;
				position: relative;
			}
		}

		&:focus {
			box-shadow: 0px 17px 13px -8px rgba($gray-500, 0.25);
		}
	}

	span + .vue-daterange-picker {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	.form-control.reportrange-text {
		background-color: transparent;
		border: none;
		cursor: pointer;
		font-size: 16px;
		padding-bottom: 10px;
		padding-left: 58px;
		padding-right: 35px;
		padding-top: 30px;
		width: 100%;
		z-index: 1;

		&::placeholder {
			color: $color-10;
		}

		&:focus-visible {
			outline: none;
		}
	}

	.daterangepicker.ltr.show-calendar.show-ranges.opensright.linked {
		background-color: white;
		border: none;
		box-shadow: 0px 8px 32px -8px rgba(112, 108, 107, 0.22);
		display: flex;
		flex-direction: column;
		margin-left: -12px;
		margin-top: -4px;
		min-width: auto;
		width: auto;

		@include media-breakpoint-up(lg) {
			min-width: 658px;
		}

		&::before,
		&::after {
			display: none;
		}

		.prev, 
		.next {
			border: none;

			&::before {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				content: '';
				display: block;
				height: 20px;
				margin: auto;
				width: 20px;
			}

			span {
				display: none;
			}
		}

		.prev::before {
			background-image: url(/assets/img/icons/chevron-left-dark.svg);
		}

		.next::before {
			background-image: url(/assets/img/icons/chevron-right-dark.svg);
		}

		.calendars {
			.ranges {
				border-bottom: 1px solid $gray-200;
				padding: 10px 20px;
				width: 100%;

				ul {
					display: flex;
					flex-wrap: wrap;
					list-style: none;
					margin: 0;
					padding: 0;
					width: auto;

					li {
						border: none;
						color: $color-10;
						flex: 1;
						font-size: 15px;
						font-weight: 600;
						margin: 0;
						padding: 6px 12px;
						text-align: center;
						white-space: nowrap;
						width: auto;

						@include media-breakpoint-up(lg) {
							flex: 0 0 auto;
						}

						&.active {
							background-color: transparent;
							border-bottom: 2px solid $color-20;
							color: $color-20;
						}
					}
				}
			}

			.calendars-container {
    			display: flex;
				margin-left: auto;
    			margin-right: auto;
    			margin-top: 6px;
    		}

			.drp-calendar.col {
				border: none;
				max-width: none;
				width: auto;
			}
		}

		.calendar-table {
			.table-condensed {
				margin: auto;
				width: auto;

				thead {
					display: table-header-group;
				}

				th, td {
					display: table-cell;
					font-weight: 600;
					font-size: 16px;
					height: 40px;
					padding: 0;
					text-align: center;
					width: 40px;
				}

				th {
					color: $text-grey;
					text-transform: capitalize;
				}

				td {
					background-color: transparent;
					border: none;
					color: $color-10;
				}

				.today {
					position: relative;

					&::after {
						border: 2px solid $color-13;
						border-radius: 50%;
						content: '';
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						position: absolute;
					}
				}

				.off {
					color: $gray-500;
					opacity: 0;
					height: 0;
					line-height: 0;
				}

				.in-range {
					background-color: $color-14;
					border-radius: 0;
					color: $color-20;

					&:hover {
						background-color: $color-13;
					}

					&.start-date.end-date {
						border-radius: 8px;
					}

					&.start-date,
					&.end-date {
						background-color: $color-20;
						color: white;
					}

					&.start-date {
						border-radius: 8px 0px 0px 8px;
					}

					&.end-date {
						border-radius: 0 8px 8px 0;
					}
				}
			}
		}

		.drp-selected {
			display: none;
		}

		.drp-buttons {
			align-self: flex-end;
			border: none;
			padding: 8px 32px 24px;

			.applyBtn.btn.btn-success {
				@extend .btn;
				@extend .btn-primary;
				order: 1;
				font-size: 14px;
				padding: 10px 14px;
			}

			.cancelBtn.btn.btn-secondary {
				background-color: transparent;
				border-color: transparent;
				box-shadow: none;
				color: $color-10;
				font-size: 14px;
				font-weight: 700;
				margin-right: 16px;

				&:hover {
					background-color: transparent;
					text-decoration: underline;
				}
			}
		}
	}
}
