.cta-link {
	align-items: center;
	display: flex;
	margin-bottom: auto;
	margin-top: auto;
}

.cta-link__button {
	display: inline-block;
	font-size: 0;
	font-weight: 700;
	text-decoration: none;
	position: relative;
	padding-left: 32px;

	@include media-breakpoint-up(lg) {
		font-size: inherit;
		padding-left: 50px;
	}

	&.is-active {
		&::after {
			border-bottom: 5px solid $color-20;
			content: '';
			position: absolute;
			bottom: -22px;
			left: 0;
			right: 0;

			@include media-breakpoint-up(lg) {
				bottom: -25px;
			}
		}
	}

	.badge {
		font-size: 14px;
		left: 21px;
		position: absolute;
		top: 3px;

		@include media-breakpoint-up(lg) {
			top: -5px;
		}
	}

	&::before {
		background-image: url(/assets/img/icons/envelope.svg);
		content: '';
		height: 32px;
		position: absolute;
		left: 0;
		top: 5px;
		width: 32px;

		@include media-breakpoint-up(lg) {
			top: -2px;
		}
	}
}

.cta-link__arrow {
	display: inline-block;
	text-decoration: none;
	position: relative;

	a {
		&::before {
			background-image: url(/assets/img/icons/chevron-left-dark.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			content: '';
			height: 10px;
			width: 5px;
			position: absolute;
			right: calc(100% + 10px);
			top: 8px;
		}
	}
}

.cta-link__user {
	display: inline-block;
}

.cta-link__user-trigger {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 20px;
	padding-left: 30px;
	position: relative;
	text-decoration: none;

	@include media-breakpoint-up(lg) {
		margin-left: 50px;
		min-height: 38px;
		padding-left: 40px;
	}

	&.active {
		&::after {
			@include media-breakpoint-up(lg) {
				border-bottom: 5px solid $color-20;
				content: '';
				position: absolute;
				bottom: -14px;
				left: 40px;
				right: 0;
			}
		}

		span {
			&:first-child {
				color: $color-20;
			}
		}
	}

	span {
		font-weight: 700;

		@include media-breakpoint-down(md) {
			display: none;
		}

		&:first-child {
			font-size: 16px;
			line-height: 20px;
		}

		&:last-child {
			font-size: 14px;
			line-height: 18px;
		}
	}

	&::before {
		background-image: url(/assets/img/icons/user.svg);
		content: '';
		height: 32px;
		left: 0;
		position: absolute;
		top: 0;
		width: 32px;

		@include media-breakpoint-up(lg) {
			top: 3px;
		}
	}
}

.cta-link__user-dropdown {
	display: none;

	a {
		text-decoration: none;
	}

	.btn {
		background-color: transparent;
		border: none;
		box-shadow: none;
		color: $color-10;
		font-size: inherit;
		padding: 0;

		&:hover {
			background-color: transparent;
			color: $color-20;
		}
	}

	ul {
		font-size: 15px;
		font-weight: 600;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			& + li {
				margin-top: 15px;
			}

			&.is-active {
				a {
					color: $color-20;
					position: relative;

					&::after {
						bottom: -8px;
						border-bottom: 3px solid $color-20;
						content: '';
						left: 0;
						position: absolute;
						width: 100%;
					}
				}
			}
		}
	}

	.cta-link__user-dropdown__info {
		display: flex;
		flex-direction: column;
		margin-bottom: 25px;
		padding-left: 40px;
		position: relative;
		text-decoration: none;

		span, a {
			font-weight: 700;

			&:first-child {
				font-size: 16px;
				line-height: 20px;
			}

			&:last-child {
				font-size: 14px;
				line-height: 18px;
			}
		}

		&::before {
			background-image: url(/assets/img/icons/user.svg);
			content: '';
			height: 32px;
			left: 0;
			position: absolute;
			top: 3px;
			width: 32px;
		}
	}

	.cta-link__user-trigger.active + & {
		background-color: $white;
		border-radius: 8px 0px 8px 8px;
		box-shadow: 0px 0px 24px -8px rgba($black, 0.16);
		display: block;
		max-width: 344px;
		padding: 24px;
		position: absolute;
		right: 0;
		top: calc(100% + 14px);
		width: calc(100vw - 30px);
	}
}