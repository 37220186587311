ul.social {
	padding: 0;
	margin: 0;
	line-height: 0;
	display: flex;
	flex-wrap: wrap;

	li {
		display: inline-block;
		position: relative;
		margin: 0 3px 3px 0;

		a {
			position: relative;
			display: block;
			text-indent: -9999px;
			width: 35px;
			height: 35px;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($black, 1);
				opacity: 0;
				transition: all 0.3s ease-in-out 0s;
			}
		}

		&.facebook a {
			background-image: url('/assets/img/icons/facebook.svg') !important;
		}

		&.twitter a {
			background-image: url('/assets/img/icons/twitter.svg') !important;
		}

		&.linkedin a {
			background-image: url('/assets/img/icons/linkedin.svg') !important;
		}

		&.google_plus a {
			background-image: url('/assets/img/icons/google-plus.svg') !important;
		}

		&.pinterest a {
			background-image: url('/assets/img/icons/pinterest.svg') !important;
		}

		&.instagram a {
			background-image: url('/assets/img/icons/instagram.svg') !important;
		}

		&.flickr a {
			background-image: url('/assets/img/icons/flickr.svg') !important;
		}

		&.tumblr a {
			background-image: url('/assets/img/icons/tumblr.svg') !important;
		}

		&.vimeo a {
			background-image: url('/assets/img/icons/vimeo.svg') !important;
		}

		&.youtube a {
			background-image: url('/assets/img/icons/youtube.svg') !important;
		}

		&.rss a {
			background-image: url('/assets/img/icons/rss.svg') !important;
		}

		&.soundcloud a {
			background-image: url('/assets/img/icons/soundcloud.svg') !important;
		}

		&.behance a {
			background-image: url('/assets/img/icons/behance.svg') !important;
		}
	}

	.no-touchevents & {
		li {
			a:hover::after {
				opacity: 0.25;
			}
		}
	}
}

.social-feed {

}

.social-feed__items {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding-left: 0;
}

.social-feed__item {
	flex: 0 1 100%;
	margin-top: 20px;
	max-width: 100%;

	@include media-breakpoint-up(lg) {
		flex: 0 1 30%;
		margin-left: 30px;
	}
}

.social-feed--youtube {
	.social-feed__item {
		width: 100%;
		height: 150px;

		@include media-breakpoint-up(sm) {
			width: 300px;
			height: 215px;
		}
	}
}
