.page-wrapper {
	
}

.cookiewall-active {
	overflow: hidden;

	.page-wrapper {
		filter: blur(10px);

		&::after {
			@include fillout;
			background-color: rgba($black, 0.2);
			content: '';
			z-index: index($elem-stack, cookiewall-overlay);
		}
	}
}
