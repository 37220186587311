.box {
	padding: $grid-gutter-width $grid-gutter-width-half;
	
	@include media-breakpoint-up(md) {
		padding: 20px;
	}

	@include media-breakpoint-up(xl) {
		padding: $grid-gutter-width;
	}

	@include media-breakpoint-up(hg) {
		padding: 40px;
	}
}

.box--white {
	background-color: $white;
	box-shadow: 0px 8px 32px -8px rgba($gray-600, 0.22);
	border-radius: 8px;
}

.box--nopadding {
	padding: 0px;
}

.box--01 {
	background-color: $color-14;
	border-radius: 16px;

	> .row {
		& + .row {
			margin-top: 40px;
		}
	}
}

.box--02 {
	background-color: $color-24;
	border-radius: 16px;

	h1, h2, h3, h4, h5, .subheading {
		color: $color-20;
	}
}

.box--03 {
	background-color: $color-34;
}

.box--04 {
	background-color: $gray-100;
}

.box--05 {
	background-color: $gray-200;
}

.box--negmargin-mob {
	margin: -32px -16px 0;
}
