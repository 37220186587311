.site-search {
	position: relative;
	margin: 15px 0;

	.form-group {
		margin-bottom: 0px;
	}

	fieldset {
		margin-bottom: 0px;
		position: relative;
		pointer-events: all;
	}

	.form-control {
		border-radius: 8px;
		border: 2px solid $gray-400;
		padding: $grid-gutter-width-half 40px $grid-gutter-width-half $grid-gutter-width-half;
		width: 100%;
	}

	.btn {
		border: 0 none;
		font-size: 25px;
		height: 60px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: 60px;

		&::before {
			background-image: url(/assets/img/icons/search-pink.svg);
			content: '';
			height: 30px;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
			position: absolute;
			width: 30px;
		}
	}

	.header & {
		margin-left: auto;
		overflow: hidden;
		
		@include media-breakpoint-down(md) {
			width: 40px;
		}

		@include media-breakpoint-up(md) {
			width: 200px;
		}

		@include media-breakpoint-up(lg) {
			width: 40px;
		}

		@include media-breakpoint-up(xl) {
			width: 200px;
		}
	}
}