.list-wrapper {
	background-color: $color-20;
	padding: 20px;
}

.list {
	padding: 0px;
	list-style: none;

	li {
		margin-left: 20px;

		& + li {
			margin-top: 8px;
		}
	}

	& + .btn {
		width: 100%;
	}
}

.list__item {
	position: relative;

	&.is-active {
		color: #E6006E;

		a {
			color: #E6006E;
		}
	}
}

.list__item--normal {
	list-style-type: disc;
}

.list__item--checkmark {
	&::before {
		background-image: url(/assets/img/icons/check-black.svg);
		background-repeat: no-repeat;
		content: "";
		height: 14px;
		left: -20px;
		position: absolute;
		top: 5px;
		width: 14px;
	}
}

li.list__item--arrow-right {
	margin-left: 0px;
	margin-right: 20px;
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 0px;
	}

	&::after {
		background-image: url(/assets/img/icons/chevron-right-dark.svg);
		background-repeat: no-repeat;
		content: "";
		height: 14px;
		right: -20px;
		position: absolute;
		top: 7px;
		width: 7px;
	}
}

.list__item--arrow {
	&::before {
		background-image: url(/assets/img/icons/chevron-right-dark.svg);
		background-repeat: no-repeat;
		content: "";
		height: 14px;
		left: -20px;
		position: absolute;
		top: 5px;
		width: 14px;
	}
}

.list__item--pdf {
	&::before {
		background-image: url(/assets/img/icons/pdf.svg);
		background-repeat: no-repeat;
		content: "";
		height: 14px;
		left: -20px;
		position: absolute;
		top: 5px;
		width: 14px;
	}
}

.list__item--download {
	&::before {
		background-image: url(/assets/img/icons/download.svg);
		background-repeat: no-repeat;
		content: "";
		height: 14px;
		left: -20px;
		position: absolute;
		top: 5px;
		width: 14px;
	}
}

.list--objects {
	.list__item {
		border-bottom: 1px solid #EDE8E6;
		margin: 0;
		padding: 24px 0;
	}
}
