.section {
	overflow: hidden;
	padding: 32px 0px;
	
	@include media-breakpoint-up(md) {
		padding: 40px 0px;
	}

	@include media-breakpoint-up(lg) {
		padding: 48px 0px;
	}

	@include media-breakpoint-up(xl) {
		padding: 56px 0px;
	}

	@include media-breakpoint-up(hg) {
		padding: 64px 0px;
	}
}

.section__heading {
	&.align-center {
		text-align: center;
	}
	&.align-right {
		text-align: right;
	}

	&.section__heading--table {
		//margin-bottom: $grid-gutter-width;

		.subheading {
			align-items: center;
			display: flex;
			justify-content: space-between;

			.dropdown-item {
				text-transform: none;
			}
		}

		.btn {
			@include media-breakpoint-up(md) {
				margin-left: auto;
			}
		}

		.dropdown {
			margin-bottom: $grid-gutter-width-half;

			@include media-breakpoint-up(md) {
				margin-left: $grid-gutter-width-half;
				margin-bottom: 0;
			}
		}

		.dropdown--export {
			@include media-breakpoint-up(md) {
				margin-left: auto;
			}
		}

		.table-search {
			flex: 1 0 550px;
		}
	}
}

.section--bg-white {
	background-color: $white;

	& + & {
		padding-top: 0;
	}
}

.section--bg-transparent {
	background-color: transparent;

	& + & {
		padding-top: 0;
	}
}

.section--bg-dark {
	background-color: $dark;

	& + & {
		padding-top: 0;
	}

	.section__heading,
	.section__title {
		color: $white;
	}
}

.section--bg-light {
	background-color: $light;

	& + & {
		padding-top: 0;
	}
}

.section--bg-color-01 {
	background-color: $color-10;

	& + & {
		padding-top: 0;
	}

	.section__heading,
	.section__title {
		color: $white;
	}
}

.section--bg-color-02 {
	background-color: $color-20;

	& + & {
		padding-top: 0;
	}
}

.section--bg-color-03 {
	background-color: $color-34;

	& + & {
		padding-top: 0;
	}
}

.section--bg-color-04 {
	background-color: $gray-100;

	& + & {
		padding-top: 0;
	}
}

.section--bg-color-05 {
	background-color: $gray-200;

	& + & {
		padding-top: 0;
	}
}

.section--breadcrumb {
	padding-bottom: 0;
	padding-top: 48px;
}

.section--nopadding {
	padding: 0;
}

.section--nopaddingbottom {
	padding-bottom: 0;
}

.section--fillwidth {
	& > .container {
		max-width: none;
		padding: 0;

		.row {
			margin-right: 0;
			margin-left: 0;

			.col-12 {
				padding: 0;
			}
		}
	}
}

.section--bg-photo {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.section--withoverlap {
	position: relative;
	padding-bottom: 130px;
		
	@include media-breakpoint-up(md) {
		padding-bottom: 150px;
	}

	& + .section {
		margin-top: -130px;

		.container {
			@include media-breakpoint-down(xs) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		@include media-breakpoint-up(md) {
			margin-top: -150px;
		}
	}
}

.section + .umb-grid > .section {
	@include media-breakpoint-up(md) {
		margin-top: -40px;
	}

	@include media-breakpoint-up(lg) {
		margin-top: -48px;
	}

	@include media-breakpoint-up(xl) {
		margin-top: -56px;
	}

	@include media-breakpoint-up(hg) {
		margin-top: -64px;
	}
}
