.popup {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    visibility: hidden;
    width: 100vw;
    z-index: 1000;

    &.is-open {
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
    }
}

.popup__background {
    position: absolute;
    background-color: rgba($text-purple, .6);
    width: 100%;
    height: 100%;
}

.popup__container {
    background-color: white;
    border-radius: 8px;
    max-height: calc(var(--vh) * 80);
    max-width: calc(100% - 32px);
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    width: 688px;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // @include media-breakpoint-up(md) {
    //     padding: 58px 56px 56px;
    // }
}

.popup__header {
    align-items: center;
    border-bottom: 1px solid #EDE8E6;
    display: flex;
    justify-content: center;
    padding: 24px 40px;

    h3 {
        font-size: 22px;
        margin: 0;
        text-align: center;
    }
}

.popup__body {
    padding: 16px 24px;
    position: relative;
}

.popup__close-button {
    align-self: center;
    background: transparent url(#{$dir-img}/icons/close-dark.svg) no-repeat 50% 50%;
    background-size: contain;
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 32px;
    width: 24px;

    @include media-breakpoint-up(md) {
        right: 32px;
        top: 36px;
    }
}

.popup__intro {
    font-size: 16px;

    // @include media-breakpoint-up(md) {
    //     font-size: 22px;
    // }
}

.popup__footer {
    .btn {
        width: 100%;

        & + .btn {
            margin-top: 16px;
        }
    }

    // @include media-breakpoint-up(md) {
    //     display: flex;
    //     gap: 24px;

    //     .btn {
    //         flex: 0 0 auto;
    //         width: auto;

    //         & + .btn {
    //             margin-top: 0;
    //         }

    //         &:first-child:last-child {
    //             flex: 0;
    //         }
    //     }
    // }
}
