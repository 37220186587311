@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  &[href] {
    &:hover,
    &:focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }
}

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: 2px 7px;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  border-radius: $badge-pill-border-radius;
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
