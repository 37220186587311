table {
	border-collapse: separate;
	border-spacing: 0px;
	margin: 0;
	display: table;
	max-width: calc(100vw - 30px);
	overflow: auto;
	width: 100%;

	@include media-breakpoint-up(sm) {
		max-width: 100%;
	}

	tr {
		border: none;

		&:empty {
			height: 8px;
		}

		td {
			background-color: $gray-200;
		}

		&:nth-last-child(2) {
			td {
				&:first-child {
					@include media-breakpoint-up(lg) {
						border-bottom-left-radius: 8px;
					}
				}

				&:last-child {
					border-bottom-right-radius: 8px;
					border-bottom-left-radius: 8px;

					@include media-breakpoint-up(lg) {
						border-bottom-left-radius: 0;
					}
				}

				&:only-child {
					@include media-breakpoint-up(lg) {
						border-bottom-left-radius: 8px;
					}
				}
			}
		}
	}

	th, td {
		border: none;
		padding: 8px 10px;
		vertical-align: bottom;
		line-height: 18px;

		@include media-breakpoint-up(lg) {
			padding: 11px 8px;
		}

		@include media-breakpoint-up(xl) {
			padding: 11px 12px;
		}

		@include media-breakpoint-up(xl) {
			padding: 13px $grid-gutter-width-half;
		}
	}

	th.sort {
		padding-right: 22px;

		span {
			cursor: pointer;
			position: relative;

			&::after, 
			&::before {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0.5em;
				content: "";
				cursor: pointer;
				height: 6px;
				opacity: .3;
				position: absolute;
				left: calc(100% + 8px);
				width: 10px;

				@include media-breakpoint-up(xl) {
					left: calc(100% + 12px);
				}
			}

			&::before {
				background-image: url(/assets/img/icons/sort-up.svg);
				bottom: 8px;

				@include media-breakpoint-up(lg) {
					bottom: 9px;
				}
			}

			&::after {
				background-image: url(/assets/img/icons/sort-down.svg);
				bottom: 1px;

				@include media-breakpoint-up(lg) {
					bottom: 2px;
				}
			}
		}

		&.sort-up span {
			&::before {
				opacity: 1;
			}
		}

		&.sort-down span {
			&::after {
				opacity: 1;
			}
		}
	}

	thead {
		color: $color-10;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	tfoot {
		h4 {
			margin: 0;
		}

		.label.label--info,
		.label.label--success,
		.label.label--danger,
		.label.label--light {
			background-color: transparent;
			color: inherit;
			font-size: inherit;
			font-weight: inherit;
		}

		tr {
			@include media-breakpoint-down(md) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
			}

			@include media-breakpoint-down(sm) {
				justify-content: flex-start;
			}

			td {
				background-color: transparent;

				@include media-breakpoint-down(md) {
					display: inline-block;

					&:empty {
						display: none;
					}

					&::before {
						content: attr(data-label);
						font-weight: 700;
						flex: 0 0 50%;
					}
				}

				@include media-breakpoint-down(sm) {
					display: flex;
					align-items: center;
					width: 100%;
				}
			}
		}
	}

	tbody {
		td {
			border-left: 2px solid $gray-200;
			border-right: 2px solid $gray-200;
			vertical-align: middle;

			@include media-breakpoint-down(md) {
				display: flex;

				&[data-label]::before {
					content: attr(data-label);
					font-weight: 700;
					flex: 0 0 50%;
				}
			}

			@include media-breakpoint-up(lg) {
				border-bottom: 2px solid $gray-200;
				border-left: none;
				border-right: none;
				border-top: 2px solid $gray-200;
			}

			&[colspan] {
				@include media-breakpoint-down(md) {
					&::before {
						display: none;
					}
				}
			}

			a {
				text-decoration: none;
			}

			&:first-child {
				border-top: 2px solid $gray-200;

				@include media-breakpoint-up(lg) {
					border-left: 2px solid $gray-200;
				}
			}

			&:last-child {
				border-bottom: 2px solid $gray-200;

				@include media-breakpoint-up(lg) {
					border-right: 2px solid $gray-200;
				}
			}
		}
	}

	.no-wrap {
		white-space: nowrap;
	}

	:not(.table-row--basic) {
		.label--light {
			background-color: $gray-100;
		}
	}

	.table-row--basic {
		td {
			background-color: white;

			&:first-child {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;

				@include media-breakpoint-up(lg) {
					border-top-right-radius: 0;
				}
			}

			&:last-child {
				@include media-breakpoint-up(lg) {
					border-top-right-radius: 8px;
				}
			}
		}

		&.is-open,
		&.is-closed {
			.icon--chevron-down {
				height: 16px;
				width: 16px;
			}

			td:first-child {
				.icon--chevron-down {
					display: none;

					@include media-breakpoint-up(lg) {
						display: block;
					}
				}
			}

			td:last-child {
				@include media-breakpoint-up(lg) {
					padding-left: 0;
					padding-right: 8px;

					.icon--chevron-down {
						display: none;
					}
				}
			}
		}

		&.is-open {
			.icon--chevron-down {
				transform: rotate(180deg);
			}
		}

		&.is-closed {
			td:first-child {
				@include media-breakpoint-up(lg) {
					border-bottom-left-radius: 8px;
				}
			}

			td:last-child {
				border-bottom-right-radius: 8px;
				border-bottom-left-radius: 8px;

				@include media-breakpoint-up(lg) {
					border-bottom-left-radius: 0;
				}
			}

			& ~ tr {
				display: none;

				&:empty {
					display: block;
				}
			}
		}

		&.has-link {
			cursor: pointer;

			td {
				transition: background-color 0.3s ease-in-out;
			}

			&:hover td {
				background-color: $gray-100;
			}
		}
	}
}

.data-table__table--empty {
	margin-top: 20px;
}

.table--details {
	tr {
		td {
			background-color: transparent;
			border: none;
			display: inline-block;
			padding-top: 0;
			padding-left: 0;
			text-align: right;
			vertical-align: text-top;
			white-space: nowrap;

			@include media-breakpoint-up(md) {
				display: table-cell;
			}

			&:first-child {
				border: none;
				display: block;
				text-align: left;
				white-space: normal;

				@include media-breakpoint-up(md) {
					display: table-cell;
					width: 44%;
				}

				@include media-breakpoint-up(lg) {
					width: 35%;
				}

				@include media-breakpoint-up(hg) {
					width: 44%;
				}
			}

			&:last-child {
				border: none;
				padding-right: 0;
				text-align: left;
				white-space: normal;
			}
		}
	}
}

.table-row--detail {
	display: none;

	.table-row--basic.is-open + & {
		display: table-row;
	}
}

table.mobile-responsive {
	th, td {
		@include media-breakpoint-down(sm) {
			border: none;
			display: block;
			padding: 0;
			max-width: calc(100vw - 70px);

			&:first-child {
				font-weight: 600;
			}
		}
	}

	tr {
		@include media-breakpoint-down(sm) {
			display: block;
			max-width: calc(100vw - 30px);
			padding: 5px 10px 2px 10px;
			width: auto;

			&:first-child {
				display: none;
			}
		}
	}
}

caption {
	padding-top: $table-cell-padding;
	padding-bottom: $table-cell-padding;
	color: $text-muted;
	text-align: left;
	caption-side: bottom;
}

th {
	// Matches default `<td>` alignment by inheriting from the `<body>`, or the
	// closest parent with a set `text-align`.
	text-align: inherit;
}

.data-table__table-detail {
	td {
		background-color: transparent;
		border: none;
		border-radius: 0;
		padding: 0.25rem 0;

		&:first-child,
		&:last-child {
			border: none;
		}    

		&:first-child {
			font-weight: 700;  
			padding-right: 20px;
		}
	}
}

.data-table__header {
	@include media-breakpoint-up(md) {
		display: grid;
		grid-template-rows: auto; 

		.data-table__title {
			display: flex;
			gap: 24px;
			grid-row: 1 / 2;
		}

		.data-table__filter-tool {
			align-items: center;
			display: flex;
			gap: 24px;
			grid-row: 2 / 3;
		}

		.table-search {
			flex: 1;
		}

		.data-table__filter-result {
			align-items: flex-end;
			display: flex;
			justify-content: space-between;
			grid-row: 3 / 4;
			margin: 24px 0;
		}

		.dropdown-wrapper {
			margin-left: auto;
		}
	}
}
