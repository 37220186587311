.top {
	display: none;

	@include media-breakpoint-up(md) {
		display: block;
		padding: 0;
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		position: absolute;
		right: 0;
		top: 15px;
	}

	.mobile-nav-open & {
		// @include media-breakpoint-down(md) {
		// 	display: block;
		// 	left: auto;
		// 	text-align: left;
		// 	width: 100%;
		// 	z-index: index($elem-stack, menu-mobile);
		// }
	}
}

.top__items {
	border-radius: 0;
	margin: 0;
	padding: 0;

	@include media-breakpoint-up(lg) {
		text-align: right;
	}
}

.top__item {
	display: block;
	margin: 15px 0;
	
	@include media-breakpoint-up(md) {
		display: inline-block;
		margin: 0 0 0 10px;
	}
}

.top__link {
	
}