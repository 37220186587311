.fixed-page {
	overflow: hidden;
}

.off-canvas__overlay {
	background-color: rgba(255, 255, 255, 0);
	bottom: 0;
	height: 100vh;
	left: -100vw;
	max-width: 100vw;
	position: fixed;
	right: 0;
	top: 175px;
	transition: background-color 0.5s ease-in-out;
	z-index: index($elem-stack, off-canvas);

	.is-open & {
		background-color: rgba(255, 255, 255, 1);
		left: 0;

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}

.off-canvas__container {
	border: none;
	left: -100vw;
	max-width: 100vw;
	padding: 0;
	position: fixed;
	top: 175px;
	transition: left 0.5s ease-in-out;
	width: 100%;
	z-index: index($elem-stack, off-canvas);

	@include media-breakpoint-up(sm) {
		width: 508px;
	}

	@include media-breakpoint-up(md) {
		left: auto;
		position: relative;
		top: 0;
		width: 100%;
	}

	.is-open & {
		display: block;
		left: 50vw;
		pointer-events: auto;
		transform: translateX(-50%);

		@include media-breakpoint-up(md) {
			left: auto;
			transform: none;
		}
	}
}

.off-canvas__close {
	background-image: url(/assets/img/icons/close-dark.svg);
	cursor: pointer;
	height: 20px;
	position: absolute;
	right: 18px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	top: 18px;
	width: 20px;
}

.off-canvas__content {
	-ms-overflow-style: scrolbar;
	border-radius: 8px;
	height: auto;
	left: auto;
	margin-left: auto;
	margin-right: 0;
	max-height: calc(100vh - 255px);
	overflow: auto;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(md) {
		background-color: $gray-100;
		box-shadow: none;
	}
}
