// Pagination

@mixin pagination-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  .page-link {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
  }

  .page-item {
    &:first-child {
      .page-link {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
