.spinner {
  align-items: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 144px;
  width: 100%;

  &::after {
    background-image: url(#{$dir-img}/spinner.svg);
    content: '';
    display: block;
    height: 124px;
    width: 124px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
