.cookie-notice {
	background-color: $white;
	position: fixed;
	bottom: -100%;
	left: 0;
	z-index: index($elem-stack, cookie-notice);
	opacity: 0;
	box-shadow: 0 0 $grid-gutter-width-half 0 rgba($black, 0);
	transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
	width: 100%;
	padding: 20px 0;

	.inner {
		margin: auto;

		@include media-breakpoint-up(md) {
			max-width: 750px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 970px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 1170px;
		}
	}

	&.open {
		bottom: 0;
		opacity: 1;
		box-shadow: 0 0 $grid-gutter-width-half 0 rgba($black, 0.05);
	}

	&.closed {
		display: none;
	}

	.heading {
		margin: 0;
	}

	.tooltip {
		@extend %tooltip-inner;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);

		&::after {
			border-color: transparent;
			border-style: solid;
			border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
			border-top-color: $tooltip-arrow-color;
			content: '';
			height: $tooltip-arrow-height;
			left: 50%;
			position: absolute;
			bottom: -5px;
			transform: translateX(-50%);
			width: $tooltip-arrow-width;
		}
	}
}

.cookie-notice__container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 70px 20px;

  @include media-breakpoint-up(md) {
	max-width: 70%;
	padding: 20px;
  }

  @include media-breakpoint-up(xl) {
	max-width: 1000px;
  }
}

.cookie-notice__options {
	width: 100%;
	margin: 20px 0 0;
}

.cookie-notice__option {
	display: flex;
}

.cookie-notice__option-btns {
	flex: 0 1 90px;
	
	form {
		display: flex;
	}
}

.cookie-notice__option-text {
	flex: 0 1 70%;
}

.cookie-notice__option-text-label {
	font-weight: 500;
}

.cookie-notice__option-yes-radio:checked,
.cookie-notice__option-yes-radio:not(:checked),
.cookie-notice__option-no-radio:checked,
.cookie-notice__option-no-radio:not(:checked) {
	position: absolute;
	left: -9999px;
}

.cookie-notice__option-yes,
.cookie-notice__option-no {
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	display: inline-block;
	cursor: pointer;
}

.cookie-notice__option-yes {
	background-image: url('/assets/img/icons/check-circle--dark.svg');
}

.cookie-notice__option-no {
	background-image: url('/assets/img/icons/cross-circle--dark.svg');
}

.cookie-notice__option-yes-radio:checked +label {
	background-image: url('/assets/img/icons/check-circle--green.svg');
}

.cookie-notice__option-no-radio:checked +label {
	background-image: url('/assets/img/icons/cross-circle--red.svg');
}
