.menu {
	display: none;
	margin: 0;

	@include media-breakpoint-down(md) {
		background-color: $gray-100;
		border-radius: 8px 8px 0px 0px;
		bottom: -300px;
		display: block;
		left: auto;
		margin-left: -15px;
		overflow: auto;
		position: fixed;
		text-align: left;
		transition: all 0.4s ease-in-out;
		width: 100%;
		z-index: index($elem-stack, navigation);
	}

	@include media-breakpoint-up(lg) {
		display: block;
		margin-left: 20px;
		order: -1;
		padding-top: 0px;
	}
}

// .menu--extended {
// 	.menu__link-text {
// 		@include media-breakpoint-up(lg) {
// 			font: 400 13px/18px $font-source-sans;
// 		}
// 	}
// }

.menu__list {
	margin-bottom: 0;
	padding-left: 0;

	@include media-breakpoint-up(lg) {
		display: inline-flex;
		border: 0 none;
		line-height: 0;
		overflow: visible;
		position: relative;
		vertical-align: top;

		&:not(:last-child) {
			border-bottom: 2px solid $light;
		}
	}
}

.menu__item {
	align-self: flex-end;
	flex: 0 1 auto;
	list-style: none;
	padding: 0;
	position: relative;
	transition: all 0.2s ease-in-out 0s;

	& + & {
		@include media-breakpoint-up(lg) {
			margin-left: 30px;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 40px;
		}
	}
}

.menu__span {
	display: flex;
	position: relative;

	&.is-active {
		a {
			position: relative;

			&::after {
				border-bottom: 4px solid $color-20;
				bottom: -5px;
				left: 0;
				content: '';
				position: absolute;
				width: 100%;
			}
		}

		.menu__link-text {
			color: $color-20;
		}

		&:hover {
			.menu__link-text {
				color: $color-22;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&:hover {
			.menu__link-text {
				color: $color-20;
			}
		}
	}
}

.menu__link {
	display: inline-block;
	padding-left: 0;
	position: relative;

	@include media-breakpoint-up(lg) {
		font-size: 15px;

		&:hover {
			text-decoration: none;
		}
	}
}

.menu__link-text {
	color: $color-10;
	display: inline-block;
	font-weight: 700;
	padding: 12px 20px;
	white-space: nowrap;

	@include media-breakpoint-up(lg) {
		margin: 25px 0;
		padding: 0;
	}
}

.menu__submenu-trigger {
	display: inline-block;
	padding: 12px 20px;
	position: absolute;
	right: 0;
	top: calc(50% - 16px);
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	&:hover {
		&::before {
			@include media-breakpoint-up(lg) {
				opacity: 0.4;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 0;
	}

	.menu__link + & {
		padding-left: 12px;
	}

	&:last-child {
		position: relative;
		transition: all 0.2s ease-in-out;

		&::after {
			background-image: url(/assets/img/icons/chevron-down-dark.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			border: none;
			content: '';
			display: inline-block;
			height: 11px;
			line-height: 1;
			margin-right: 12px;
			opacity: 1;
			position: relative;
			bottom: auto;
			left: auto;
			text-align: center;
			width: 17px;
		}

		.open & {
			&::after {
				background-image: url(/assets/img/icons/chevron-up-dark.svg);
			}
		}
	}
}

.menu__subwrap {
	background-color: $color-10;
	display: none;

	.open & {
		display: block;
	}

	@include media-breakpoint-up(lg) {
		box-shadow: 0 0 10px 0 rgba($black, 0.5);
		height: auto;
		left: -999em;
		opacity: 0;
		padding: 0;
		position: absolute;
		text-align: left;
		width: $base-nav-main-dropdown-width_sm;

		.open & {
			left: 0;
			opacity: 1;
		}
	}

	.menu__list {
		display: block;
	}
}

.menu__subitem {
	list-style: none;

	.menu__span {
		width: 100%;

		a {
			color: $color-20;
			display: block;
			font: 400 14px/17px $font-source-sans;
			padding: 12px 20px;
			text-decoration: none;
			width: 100%;

			&:hover {
				font-weight: 700;
				text-decoration: none;
			}

			@include media-breakpoint-up(lg) {
				color: $white;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				font-family: $font-source-sans;
				padding: 24px 0 0;
				width: auto;
			}
		}

		&.is-active {
			a {
				@include media-breakpoint-up(lg) {
					font-weight: 700;
				}
			}
		}
	}
}

.multi-col {
	.menu__subwrap {
		padding: 10px 0;

		@include media-breakpoint-up(lg) {
			width: $base-nav-main-dropdown-width-wider_sm;
		}
	}

	.menu__list {
		@include media-breakpoint-up(sm) {
			padding-left: 0;
			column-count: 2;
			column-gap: 0px;
		}

		@include media-breakpoint-up(md) {
			column-count: 3;
		}
	}

	.menu__subitem {
		@include media-breakpoint-up(sm) {
			break-inside: avoid;
		}
	}
}

.mobile-nav {
	@include media-breakpoint-down(md) {
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		margin-left: auto;
	}
}

.mobile-nav-open {
	.menu {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}

		@include media-breakpoint-up(md) {
			max-width: 740px;
		}

		@include media-breakpoint-down(md) {
			bottom: 64px;
		}
	}
}

.mobile-nav-open {
	@include media-breakpoint-down(md) {
		overflow: hidden;
	}
}

// Bottom mobile navigation
.mob-menu {
	background-color: $white;
	bottom: 0;
	box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.16);
	left: 0;
	padding-right: 20%;
	position: fixed;
	transition: transform 0.3s ease-in-out 0s;
	width: 100%;
	z-index: index($elem-stack, menu-mobile);

	display: flex;
	justify-content: center;
	align-items: stretch;

	@include media-breakpoint-up(lg) {
	  display: none;
	}
}

.mob-menu__item {
	align-self: center;
	display: flex; // childeren
	flex-direction: column; // childeren
	flex: 1 1 auto; // this
	justify-content: center; // childeren
	padding: 8px 0;
	position: relative;
	text-decoration: none;

	span {
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		white-space: nowrap;
		font-weight: 700;
	}

	@include media-breakpoint-up(xl) {
		display: none;
	}
}

.mob-menu__icon {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	width: 36px;
	height: 36px;
	margin: 0 auto 0px auto;
	display: block;

	&.mob-menu__icon--overview {
		background-image: url(/assets/img/icons/overview.svg);
	}

	&.mob-menu__icon--loans {
		background-image: url(/assets/img/icons/loans.svg);
	}

	&.mob-menu__icon--grants {
		background-image: url(/assets/img/icons/grants.svg);
	}

	&.mob-menu__icon--running-account {
		background-image: url(/assets/img/icons/running-account.svg);
	}

	&.mob-menu__icon--requests {
		background-image: url(/assets/img/icons/requests.svg);
	}

	&.mob-menu__icon--documents {
		background-image: url(/assets/img/icons/documents.svg);
	}
}

.mob-menu__close {
	display: none;
}

.reveal-out {
	.mob-menu__item { 
		display: none; 
	}
	
	.mob-menu__close {
		background-color: $gray-200;
		display: block;
		width: 100%;
		text-align: center;
		padding: 16px 0;
		
		span {
			font: 400 18px/26px $font-source-sans;
			white-space: nowrap;
			text-align: center;
			color: $color-34;
		}
	}
}