.label {
	border-radius: 16px;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	height: 30px;
	line-height: 25px;
	padding: 3px 8px;
	vertical-align: middle;
	white-space: nowrap;

	@include media-breakpoint-up(lg) {
		padding: 3px 16px;
	}

	&::before {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		display: inline-block;
		height: 12px;
		margin-right: 8px;
		position: relative;
		top: 1px;
		width: 12px;
	}

	&.label--valuta {
		border-radius: 8px;
		font-size: 16px;
		font-weight: 400;
		height: 50px;
		line-height: 45px;
		margin-bottom: -7px;
		margin-top: -7px;
		width: 100%;

		&::before {
			display: none;
		}
	}

	&.label--info,
	&.label--success,
	&.label--danger,
	&.label--light {
		.running-account__table & {
			color: $text-purple;
		}
	}

	&.label--info {
		background-color: $color-34;
		color: #005F77;

		&::before {
			background-color: #005F77;
			border-radius: 50%;
			height: 8px;
			width: 8px;
			top: -1px;
		}
	}

	&.label--success {
		background-color: $color-54;
		color: #265430;

		&::before {
			background-image: url(/assets/img/icons/check-green.svg);
		}
	}

	&.label--danger {
		background-color: $color-64;
		color: #B22D30;

		&::before {
			background-image: url(/assets/img/icons/warning.svg);
		}
	}

	&.label--light {
		background-color: $light;
		color: $text-purple;

		&::before {
			background-image: url(/assets/img/icons/close-dark.svg);
		}
	}
}