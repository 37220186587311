// Based on https://github.com/drygiel/csslider

$numslides: 10;

.easyslider {
	position: relative;

	> input[type='radio'] {
		display: none;
	}
}

.easyslider__slides {
	font-size: 0;
	line-height: 0;
	margin: 0 24px;
	overflow: hidden;
	padding: 0;
	position: relative;
	white-space: nowrap;
	z-index: 1;

	&.banner {
		margin: 0;
	}
}

.easyslider__slide {
	@include ms(0);
	display: inline-block;
	line-height: normal;
	list-style: none;
	margin: 0;
	padding: 15px 0 22px;
	transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
	white-space: normal;
	width: 100%;

	.easyslider__slides--02 & {
		@include media-breakpoint-up(md) {
			flex: 1 0 50%;
			width: 50%;
		}
	}

	.easyslider__slides--03 & {
		@include media-breakpoint-up(md) {
			flex: 1 0 50%;
			width: 50%;
		}

		@include media-breakpoint-up(lg) {
			flex: 1 0 33.33%;
			width: 33.33%;
		}
	}
}

@for $i from $numslides through 1 {
	.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__slides .easyslider__slide:first-child {
		margin-left: #{($i - 1) * -100%};
	}
}

@include media-breakpoint-up(md) {
	@for $i from $numslides through 1 {
		.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__slides--02 .easyslider__slide:first-child {
			margin-left: #{($i - 1) * -50%};
		}
	}
	@for $i from $numslides through 1 {
		.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__slides--03 .easyslider__slide:first-child {
			margin-left: #{($i - 1) * -50%};
		}
	}	
}

@include media-breakpoint-up(lg) {
	@for $i from $numslides through 1 {
		.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__slides--03 .easyslider__slide:first-child {
			margin-left: #{($i - 1) * -33.33%};
		}
	}
}	

.easyslider__arrow {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: none;
	position: absolute;
	top: 30px;
	background-repeat: no-repeat;
	background-size: contain;
	height: 51px;
	width: 50px;

	&.is-disabled {
		cursor: default;
		pointer-events: none;
	}
}

// prev arrows:
@for $i from 1 through $numslides {
	.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__arrows .easyslider__arrow:nth-child(#{$i - 1}) {
		@extend %btn--slider-prev;
		display: block;
	}
}

.easyslider > input:first-child:checked ~ .easyslider__arrows .easyslider__arrow:last-child:not(:nth-child(2)) {
	@extend %btn--slider-prev;
	cursor: default;
	display: block;
	pointer-events: none;
	opacity: 0.2;
}

// next arrows:
@for $i from 1 through $numslides {
	.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__arrows .easyslider__arrow:nth-child(#{$i + 1 % $numslides}) {
		@extend %btn--slider-next;
		display: block;
	}
}

.easyslider > input:last-of-type:checked ~ .easyslider__arrows .easyslider__arrow:first-child:not(:nth-last-child(2)) {
	@extend %btn--slider-next;
	cursor: default;
	display: block;
	pointer-events: none;
	opacity: 0.2;
}

.easyslider__navigation {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 10px 0 20px;	

	li {
		display: inline-block;

		&.is-active a::after {
			background-color: $color-10;
		}
	}
}

.easyslider__dot {
	cursor: pointer;
	display: block;

	&::after {
		background-color: lighten($body-color, 50%);
		content: '';
		border-radius: 50%;
		display: block;
		height: 10px;
		margin: 4px;
		transition: all 0.2s ease-in-out;
		width: 10px;
	}
}

@for $i from 1 through $numslides {
	.easyslider > input:nth-child(#{$i}):checked ~ .easyslider__navigation .easyslider__dot:nth-child(#{$i})::after {
		background-color: $body-color;
	}
}

.easyslider__content {
	display: flex;
	align-items: center;
}


.easyslider--fade {
	.easyslider__slide {
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity 0.5s ease-in-out;
		z-index: -1;

		&:only-child {
			opacity: 1;
		}
	}

	@for $i from $numslides through 1 {
		& > input:nth-child(#{$i}):checked ~ .easyslider__slides .easyslider__slide {
			&:first-child {
				margin-left: 0;
			}
			&:nth-child(#{$i}) {
				opacity: 1;
				z-index: 1;
			}
			&:nth-child(#{$i + 1 % $numslides}) {
				z-index: 0;
			}
		}
	}
}
