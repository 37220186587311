.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	margin-top: $grid-gutter-width;
}

.page-item {
	&:first-child,
	&:last-child {
		.page-link {
			background-color: transparent;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			color: transparent;
			display: inline-block;
			width: 25px;
		}
	}

	&:first-child {
		.page-link {
			background-image: url(/assets/img/icons/chevron-left-thin-dark.svg);
			border-bottom-left-radius: $border-radius;
			border-top-left-radius: $border-radius;
			margin-left: 0;
			margin-right: 8px;

			&:hover {
				background-image: url(/assets/img/icons/chevron-left-thin-pink.svg);
			}
		}
	}

	&:last-child {
		.page-link {
			background-image: url(/assets/img/icons/chevron-right-thin-dark.svg);
			border-bottom-right-radius: $border-radius;
			border-top-right-radius: $border-radius;
			margin-right: 0;

			&:hover {
				background-image: url(/assets/img/icons/chevron-right-thin-pink.svg);
			}
		}
	}

	&.active .page-link {
		z-index: 1;
		color: $pagination-active-color;
		background-color: $pagination-active-bg;
	}

	&.disabled {
		.page-link {
			cursor: default;
			pointer-events: none;
		}

		&:first-child,
		&:last-child {
			opacity: 0;
		}
	}
}

.page-link {
	background-color: $pagination-bg;
	border-radius: 50%;
	color: $pagination-color;
	display: block;
	height: 32px;
	line-height: 32px;
	margin: 0 3px;
	padding: 0;
	position: relative;
	text-align: center;
	text-decoration: none;
	width: 32px;

	@include media-breakpoint-up(md) {
		margin: 0 8px;
	}

	@include media-breakpoint-up(lg) {
		line-height: 35px;
		height: 35px;
		width: 35px;
	}

	&:hover {
		color: $pagination-hover-color;
		text-decoration: none;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;
	}

	&:focus {
		outline: 0;
	}

	// Opinionated: add "hand" cursor to non-disabled .page-link elements
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}


//
// Sizing
//

.pagination-lg {
	@include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
	@include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}


// used in grid/components, set to hidden because some versions don't fit on mobile screen
.overflow-auto {
	overflow: hidden;
}
