//
// Headings
//

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	margin-bottom: $headings-margin-bottom;
	font-family: $font-basic-commercial;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;
}

h1, .h1 {
	font-size: 38px;
	line-height: 46px;

	@include media-breakpoint-up(xl) {
		font-size: 44px;
		line-height: 55px;
	}

	@include media-breakpoint-up(hg) {
		font-size: 50px;
		line-height: 64px;
	}
}

h2, .h2 {
	font-size: 28px;
	line-height: 36px;

	@include media-breakpoint-up(xl) {
		font-size: 34px;
		line-height: 45px;
	}

	@include media-breakpoint-up(hg) {
		font-size: 40px;
		line-height: 54px;
	}
}

h3, .h3 {
	font-size: 24px;
	line-height: 32px;

	@include media-breakpoint-up(xl) {
		font-size: 28px;
		line-height: 38px;
	}

	@include media-breakpoint-up(hg) {
		font-size: 32px;
		line-height: 44px;
	}
}

h4, .h4 {
	font-size: 20px;
	line-height: 28px;

	@include media-breakpoint-up(xl) {
		font-size: 22px;
		line-height: 32px;
	}
}

h5, .h5 {
	font-size: 16px;
	line-height: 24px;

	@include media-breakpoint-up(xl) {
		font-size: 18px;
		line-height: 28px;
	}
}

.subheading {
	font-size: 13px;
	text-transform: uppercase;

	@include media-breakpoint-up(xl) {
		font-size: 15px;
	}
}

.intro {
	font-size: 16px;
	line-height: 24px;

	@include media-breakpoint-up(xl) {
		font-size: 18px;
		line-height: 28px;
	}
}

.lead {
	font-size: $lead-font-size;
	font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
	font-size: $display1-size;
	font-weight: $display1-weight;
	line-height: $display-line-height;
}
.display-2 {
	font-size: $display2-size;
	font-weight: $display2-weight;
	line-height: $display-line-height;
}
.display-3 {
	font-size: $display3-size;
	font-weight: $display3-weight;
	line-height: $display-line-height;
}
.display-4 {
	font-size: $display4-size;
	font-weight: $display4-weight;
	line-height: $display-line-height;
}


//
// Horizontal rules
//

hr {
	margin-top: $hr-margin-y;
	margin-bottom: $hr-margin-y;
	border: 0;
	border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
	font-size: $small-font-size;
	font-weight: $font-weight-normal;
}

mark,
.mark {
	padding: $mark-padding;
	background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
	padding-left: 0;
	list-style: none;
}
.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: $list-inline-padding;
	}
}


//
// Misc
//

// Builds on `abbr`
.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

// Blockquotes
.blockquote {
	margin-bottom: $spacer;
	font-size: $blockquote-font-size;
}

.blockquote-footer {
	display: block;
	font-size: 80%; // back to default font-size
	color: $blockquote-small-color;

	&::before {
		content: "\2014 \00A0"; // em dash, nbsp
	}
}
